import React from "react";
import {
  Flex,
  Box,
  Text,
  Image,
  Link,
  Stack,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const Header = () => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const fontSize = useBreakpointValue({ base: "sm", md: "md" });
  const logoSize = useBreakpointValue({ base: "20px", md: "43px" });

  return (
    <Flex
      minHeight="60px"
      alignItems="center"
      justifyContent={isSmallScreen ? "space-between" : "space-evenly"}
      px={6}
      py={4}
      boxShadow="md"
      zIndex={1000}
      width="full" // Ensure it spans the full width.
    >
      <Image
        src="./khreej_logo.png"
        alt="Khreej Logo"
        height={logoSize}
        mx={2}
        href="/"
        onClick={() => window.location.replace("/")}
      />

      {isSmallScreen && (
        <Box>
          <Link href="/form" _hover={{ textDecoration: "none" }}>
            <Text
              as="span"
              fontWeight="700"
              border="1px"
              borderColor="primary"
              borderRadius="md"
              p={2}
              fontSize={fontSize}
            >
              سجل الآن
            </Text>
          </Link>
        </Box>
      )}

      {isSmallScreen ? (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="primary"
            border="1px"
            m={2}
            bg="white"
            color="primary"
          />
          <MenuList color="primary">
            <MenuItem as={Link} href="/" color="primary">
              الرئيسية
            </MenuItem>
            <MenuItem as={Link} href="https://khreej.co/#FAQs">
              الأسئلة الشائعة
            </MenuItem>
            <MenuItem as={Link} href="/aboutus" color="primary">
              من نحن؟
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Stack
          direction="row"
          spacing={9}
          flexWrap="wrap"
          justifyContent="center"
          mx={2}
        >
          <Stack
            direction="row"
            spacing={9}
            flexWrap="wrap"
            justifyContent="center"
            mx={2}
          >
            <Link
              href="/"
              _hover={{ textDecoration: "none" }}
              fontWeight="700"
              fontSize={fontSize}
              color="grey"
            >
              الرئيسية
            </Link>
            <Link
              href="/aboutus"
              _hover={{ textDecoration: "none" }}
              fontWeight="700"
              fontSize={fontSize}
              color="grey"
            >
              من نحن؟{" "}
            </Link>
            <Link
              href="https://twitter.com/messages/compose?recipient_id=1787960043691712512"
              _hover={{ textDecoration: "none" }}
              fontWeight="700"
              fontSize={fontSize}
              color="grey"
            >
              تواصل معنا
            </Link>
          </Stack>{" "}
        </Stack>
      )}

      {!isSmallScreen && (
        // For larger screens, show the button last
        <Box>
          <Link href="/form" _hover={{ textDecoration: "none" }}>
            <Text
              as="span"
              fontWeight="700"
              border="1px"
              borderColor="primary"
              borderRadius="md"
              p={2}
              fontSize={fontSize}
            >
              سجل الآن{" "}
            </Text>
          </Link>
        </Box>
      )}
    </Flex>
  );
};

export default Header;
