import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Box,
  Text,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const LoadingModal = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${bounce} 1s ease-in-out infinite`;

  return (
    <>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px)" // This blurs the background
          />
          <ModalContent
            rounded="md"
            boxShadow="md"
            m={0}
            bg="white"
            textAlign="center"
            p={5}
          >
            <Box
              as="span"
              fontSize="4xl"
              display="inline-block"
              animation={animation}
            >
              🎓
            </Box>
            <Text fontSize="lg" mt={4}>
              قاعدين نستلم معلوماتك ياخريج ✨
            </Text>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LoadingModal;
