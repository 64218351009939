import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./features/header/Header";
import Home from "./features/home/Home";
import Footer from "./features/footer/Footer";
import Form from "./features/form/Form";
import { Box } from "@chakra-ui/react";
import AboutUs from "./features/aboutus/AboutUs";
import RulesAndRegulations from "./features/rules/RulesAndRegulations";
import GetPreview from "./features/getPreview/getPreview";
function App() {
  return (
    <>
      <Header />
      <Box mt={10}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form" element={<Form />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/rules" element={<RulesAndRegulations />} />
            <Route path="/preview" element={<GetPreview />} />
          </Routes>
        </Router>
      </Box>
      <Footer />
    </>
  );
}

export default App;
