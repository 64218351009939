import React from "react";
import { Box, Text, VStack, Icon } from "@chakra-ui/react";
import { FaGraduationCap, FaUserGraduate, FaChartBar } from "react-icons/fa";
import { FaRegLightbulb, FaLink, FaRegSquare } from "react-icons/fa";

const icons = {
  graduate: FaUserGraduate,
  lecturer: FaGraduationCap,
  stats: FaChartBar,
  motivation: FaRegLightbulb,
  connection: FaLink,
  personal: FaRegSquare,
};

const ComponentCard = ({ title, description, iconName }) => {
  const IconComponent = icons[iconName];

  return (
    <Box 
      boxShadow="md" 
      p="6" 
      rounded="md" 
      bg="white" 
      width="300px" 
      m={4}
      _hover={{ transform: "scale(1.05)" }}
      transition="transform 0.2s"
    >
      <VStack spacing="4" align="start">
        <Box boxSize="12">
          <Icon
            as={IconComponent}
            boxSize="100%"
            color="secondary"
            width={"30px"}
          />
        </Box>
        <Text fontSize="lg" fontWeight="bold">
          {title}
        </Text>
        <Text textAlign="start">{description}</Text>
      </VStack>
    </Box>
  );
};

export default ComponentCard;