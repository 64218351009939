import React from "react";
import { Text, Image, Icon, Flex, useTheme } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { RiDoubleQuotesL } from "react-icons/ri";

const CommentCard = ({ text, author, rating, authorImage }) => {
  const theme = useTheme();

  const renderStars = (rating) => {
    return Array(5)
      .fill("")
      .map((_, index) => (
        <StarIcon
          key={index}
          color={
            index < rating ? theme.colors.yellow[400] : theme.colors.gray[300]
          }
          mr={1}
        />
      ));
  };

  return (
    <Flex
      direction="column"
      align="right"
      p={5}
      style={{ boxShadow: "0px 0px 10px 4px rgba(0, 0, 0, 0.07)" }} // Add this line
      bg="white"
      borderRadius="2xl"
      zIndex={2}
      m={7}
      position="relative"
      overflow={"visible"}
      height={"200px"}
      justifyContent={"space-between"}
    >
      <Icon
        as={RiDoubleQuotesL}
        position="absolute"
        color={theme.colors.primary[500]} // Replace with your theme's primary color
        fontSize="4xl"
        top={-5}
        left={4}
      />
      <Flex direction="row" align="center" mb={2}>
        {/* <Image
          borderRadius="full"
          boxSize="30px"
          src={"./author.png"}
          alt={`Picture of ${author}`}
        /> */}
        <Text fontWeight="bold" ml={2}>
          {author}
        </Text>
      </Flex>
      <Text textAlign="justify" mb={2}>
        {text}
      </Text>
      <Flex mt={1}>{renderStars(rating)}</Flex>
    </Flex>
  );
};

export default CommentCard;
