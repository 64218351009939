import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Input,
  Button,
  Skeleton,
  useToast,
  Alert,
  AlertIcon,
  Heading,
  Icon,
  Link,
} from "@chakra-ui/react";
import { SupabaseClient } from "@supabase/supabase-js";
import useUserStore from "../form/stores/userStore";
import { createClient } from "@supabase/supabase-js";
import Validator from "../form/utils/Validator";
import { FaUserGraduate, FaIdBadge, FaArrowRight, FaShoppingCart } from "react-icons/fa";

const GetPreview = () => {
  const [studentId, setStudentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userInfo = useUserStore((state) => state.userInfo);
  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
  const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);
  const toast = useToast();

  const handleFormRedirect = () => {
    window.location.href = "/form";
  };

  const handleShopRedirect = () => {
    window.location.href = "https://shopKhreej.com";
  };

  const handleFetchData = async () => {
    if (!Validator.isValidStudentID(studentId)) {
      toast({
        title: "تاكد من صحة رقم الطالب",
        description: "رقم الطالب يتكون من ٩ ارقام ويبدا بـ٤",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("yearbook_entries")
        .select("*")
        .eq("studentId", studentId)
        .order("created_at", { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      setUserInfo(data);
    } catch (error) {
      toast({
        title: "مالقيناك 🤥",
        description: "دورنا عليك ومالقينا طالب بهالرقم، تاكد من الرقم",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!userInfo.studentId) {
    return (
      <VStack spacing={4} m={10} align="center">
        <Heading as="h1" size="xl" mb={4}>
          <Icon as={FaUserGraduate} ml={2} />
          نحن في خريج نقدرك كثيرًا
        </Heading>

        <Text fontSize="md" textAlign="center" mt={4}>
          تتيح لك هذه الخدمة تشوف صورتك اللي بتصير بالكتاب علشان تراجعها وتتاكد
          ان كل شي تمام، اذا تبي تغير شي اضغط على الزر اللي تحت وسلم الفورم مرة
          ثانية بنفس الرقم الجامعي قبل يقفل وقت التسجيل!
        </Text>
        <Input
          borderRadius="md"
          _placeholder={{ color: "gray.400" }}
          boxShadow="sm"
          placeholder="الرقم الجامعي (مثال ٤٢٠٣٨٤٢٩٣)"
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
          mt={4}
          maxW="400px"
          size="lg"
          textAlign="center"
        />
        <Button
          onClick={handleFetchData}
          isLoading={loading}
          mt={4}
          colorScheme="teal"
          size="lg"
          leftIcon={<Icon as={FaIdBadge} />}
        >
          ورني
        </Button>
        <Alert status="warning" mt={4}>
          <AlertIcon />
          <Text fontSize="md">
            المعلومات هذه بتنحط في الكتاب ضمن نسخة عامة للكل.
          </Text>
        </Alert>
      </VStack>
    );
  }

  return (
    <VStack>
      <Box
        maxWidth="100%"
        mx="auto"
        boxShadow="md"
        p={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="lg"
        position="relative"
        zIndex="1"
      >
        <Alert status="warning" mt={4}>
          <AlertIcon />
          <Text fontSize="md">
            المعلومات هذه بتنحط في الكتاب ضمن نسخة عامة للكل.
          </Text>
        </Alert>
        <PreviewComponent />
        <Text textAlign="center" p={5}>
          نحن في خريج نقدرك كثيراً، شوف كيف بطاقتك حلوه وتناسب طلتك!
        </Text>
      </Box>
      <Button
        onClick={handleFormRedirect}
        colorScheme="teal"
        size="lg"
        leftIcon={<Icon as={FaArrowRight} />}
        m={2}
      >
        خذني إلى الفورم
      </Button>
      <Button
        onClick={handleShopRedirect}
        colorScheme="orange"
        size="lg"
        leftIcon={<Icon as={FaShoppingCart} />}
        m={2}
      >
        اذهب إلى المتجر
      </Button>
    </VStack>
  );
};

const PreviewComponent = () => {
  const { userInfo } = useUserStore();
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (userInfo.image) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = "high";
        const dataUrl = canvas.toDataURL();
        setImageSrc(dataUrl);
      };
      img.src = userInfo.image;
    }
  }, [userInfo.image]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      p={5}
      boxShadow="lg"
      rounded="md"
      w="100%"
      flexDirection={"row"}
      overflow={"hidden"}
      zIndex={10}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          width={"100px"}
          height={"100px"}
          style={{ marginLeft: "20px", borderRadius: "5%" }}
        />
      ) : (
        <Skeleton circle height="100px" width="100px" ml={10} />
      )}
      <VStack align="flex-start" spacing={3} w="100%">
        {userInfo.firstName || userInfo.middleName || userInfo.lastName ? (
          <Text fontSize={"xl"} fontWeight="bold" color={"secondary"}>
            {userInfo.firstName} {userInfo.middleName} {userInfo.lastName}
          </Text>
        ) : (
          <>
            <Skeleton height="15px" width="100%" />
            <Skeleton height="15px" width="100%" />
            <Skeleton height="15px" width="100%" />
          </>
        )}
        {userInfo.email ? (
          <Text fontSize={"xl"} color={"secondary"}>
            {userInfo.email}
          </Text>
        ) : (
          <Skeleton height="20px" width="70%" />
        )}
        {userInfo.major ? (
          <Text fontSize={"xl"} color={"secondary"}>
            {userInfo.major}
          </Text>
        ) : (
          <Skeleton height="15px" width="50%" />
        )}
        {userInfo.memory ? (
          <Text
            fontSize="sm"
            color={"secondary"}
            overflow={"hidden"}
            maxWidth={"12rem"}
          >
            {userInfo.memory}
          </Text>
        ) : (
          userInfo && (
            <>
              <Skeleton height="15px" width="100%" />
              <Skeleton height="15px" width="100%" />
              <Skeleton height="15px" width="100%" />
            </>
          )
        )}
      </VStack>
    </Box>
  );
};

export default GetPreview;
