import { create } from "zustand";

const useStepStore = create((set) => ({
  currentStep: 0,
  steps: ["المعلومات الشخصية", "المعلومات الأكاديمية", "تأكيد المعلومات", "تأكيد الطلب"],
  setCurrentStep: (step) => set(() => ({ currentStep: step })),
  nextStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
  prevStep: () => set((state) => ({ currentStep: state.currentStep - 1 })),
}));

export default useStepStore;
