// Footer.js
import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Image,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

const { version } = require("../../../package.json");

const Footer = () => {
  const logoSize = useBreakpointValue({ base: "20px", md: "43px" });

  return (
    <Box as="footer" color="gray" py={10}>
      <Flex
        direction={["column", "row"]}
        align="center"
        justifyContent="space-evenly"
        width={"full"}
      >
        <Image src="./khreej_logo.png" alt="Khreej Logo" height={logoSize} />

        <Flex direction="row" align={["center", "flex-end"]} mt={[4, 0]}>
          <Link href="/rules">
            <Text fontSize="md" px={5}>
              الشروط والأحكام
            </Text>
          </Link>{" "}
          <Link href="/aboutus">
            <Text fontSize="md">من نحن؟</Text>
          </Link>{" "}
        </Flex>

        <Stack direction="row" spacing={6}>
          <Link href="https://x.com/khreej_sa">
            <IconButton
              aria-label="Twitter"
              icon={<FaTwitter />}
              isRound={true}
              size="lg"
              bg="transparent"
              _hover={{ bg: "blue.800" }}
              color="gray"
            />
          </Link>
          <Link href="https://instagram.com/khreej_sa">
            <IconButton
              aria-label="Instagram"
              icon={<FaInstagram />}
              isRound={true}
              size="lg"
              bg="transparent"
              _hover={{ bg: "blue.800" }}
              color="gray"
            />
          </Link>
        </Stack>
      </Flex>

      <Text textAlign="center" mt={10} fontSize="sm">
        © جميع الحقوق محفوظة لشركة خريج 2024 - v{version} Beta
      </Text>
    </Box>
  );
};

export default Footer;
