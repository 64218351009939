import { createClient } from "@supabase/supabase-js";
import COLLEGES from "../../features/form/enums/Colleges";
import UNIVERSITIES from "../../features/form/enums/Universities";
import { decode } from "base64-arraybuffer";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

async function uploadImage(base64Image, userInfo) {
  const base64Data = base64Image.split(",")[1];

  const collegeKey = getKeyByValue(COLLEGES, userInfo.college);
  const universityKey = getKeyByValue(UNIVERSITIES, userInfo.university);

  const fileName = `${universityKey}/${userInfo.graduationYear}/${userInfo.gender}/${collegeKey}/${userInfo.studentId}.png`; // Assuming the image is in PNG format

  const { data, error } = await supabase.storage
    .from("images")
    .upload(fileName, decode(base64Data), {
      contentType: "image/png",
      cacheControl: "3600",
      upsert: true,
    });

  if (error) {
    throw new Error(error.message);
  }

  const publicUrl = `${SUPABASE_URL}/storage/v1/object/public/images/${fileName}`;

  return publicUrl;
}

async function postUserInfo(userInfo) {
  try {
    const imageFile = userInfo.image;
    const imageUrl = await uploadImage(imageFile, userInfo);

    if (imageUrl) {
      delete userInfo.image;
    }
    userInfo.image = imageUrl;

    userInfo.university = getKeyByValue(UNIVERSITIES, userInfo.university);
    const collegeKey = getKeyByValue(COLLEGES, userInfo.college);

    const fileName = `${"KSU"}/${userInfo.graduationYear}/${
      userInfo.gender
    }/${collegeKey}/${userInfo.studentId}.png`;
    const publicUrl = `${SUPABASE_URL}/storage/v1/object/public/preview_component/${fileName}`;
    userInfo.preview_image = publicUrl;
    const { data, error } = await supabase
      .from("yearbook_entries")
      .insert([userInfo]);

    if (error) {
      throw new Error(error.message);
    }

    return data;
  } catch (error) {
    console.error("There was a problem with the operation:", error);
    throw error;
  }
}

export const uploadPreviewComponent = async (base64Image, userInfo) => {
  const base64Data = base64Image.split(",")[1];
  const arrayBuffer = decode(base64Data);
  const collegeKey = getKeyByValue(COLLEGES, userInfo.college);
  const universityKey = getKeyByValue(UNIVERSITIES, userInfo.university);
  const fileName = `${"KSU"}/${userInfo.graduationYear}/${
    userInfo.gender
  }/${collegeKey}/${userInfo.studentId}.png`;

  const { data, error } = await supabase.storage
    .from("preview_component")
    .upload(fileName, arrayBuffer, {
      // Upload the ArrayBuffer
      contentType: "image/png",
      cacheControl: "3600",
      upsert: true,
    });

  if (error) throw new Error(error.message);

  const publicUrl = `${SUPABASE_URL}/storage/v1/object/public/preview_component/${fileName}`;

  return publicUrl;
};
export default postUserInfo;
