import React from "react";
import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import CommentCard from "./widgets/CommentCard";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const GraduateComments = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const comments = [
    {
      text: "شكرًا جزيلًا على هذا المشروع، وكل ما قدمتوا من جهود في توثيق الخريجين والخريجات في الكتاب، أو دعمكم لنا. ما يزيد فخري بتخرجي من هذا الصرح العظيم جامعة الملك سعود هو وجود أشخاص يشكلون مصدر إلهام لغيرهم، ويشجعون على النجاح والتفوق في المستقبل، ونتمنى القادم أجمل. ",
      author: "منى ابراهيم المخلفي",
      rating: 5,
    },
    {
      text: "شكرا على الفكرة الحلوة! جدا متحمسة استلم نسختي واشوف مجهودكم 🩷",
      author: "في الحارثي",
      rating: 5,
    },
    {
      text: "اشكركم على عملكم الرائع وعلى حسن تصميمكم للموقع ويسعدني استماعكم لرغبات الخرييجين لتمديد المهلة والله يسلم اناملكم و يوفقكم ويسعدكم حيثما كنتم وبالتوفيق للجميع.",
      author: "عبدالله بن عبدالعزيز بن حمد العليوي",
      rating: 5,
    },
    {
      text: "فريق عمل متميز وحركي ويحب المساعدة! شكرًا لكل من هم خلف هذا العمل المبدع! حقاً شكرًا لكم!",
      author: "بدر الشمالي",
      rating: 5,
    },
    {
      text: "شكراً لكل من قضى من وقته ولو دقائق لهذا العمل! جزاكم الله كل الخير واسعدكم في الدنيا و الآخرة 💓",
      author: "إياد الغامدي",
      rating: 5,
    },
  ];

  return (
    <Box overflow="visible" position="relative" py={10} mx={2}>
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" my={6}>
        أراء الخريجين
      </Text>

      <Swiper
        spaceBetween={30}
        slidesPerView={isLargerThan768 ? 3 : 1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        centeredSlides={true}
        centeredSlidesBounds={true} // Add this line
        loop={true}
      >
        {comments.map((comment, index) => (
          <SwiperSlide key={index}>
            <CommentCard
              text={comment.text}
              author={comment.author}
              rating={comment.rating}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default GraduateComments;
