import React from "react";
import { Box, Image, useBreakpointValue } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const BookShowcase = () => {
  const showcaseImages = Array.from(
    { length: 5 },
    (_, i) => `./mockups/mockup${i + 1}.png`
  );
  const paginationSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box p={6}>
      <Box width="full">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          style={{
            "--swiper-navigation-color": "primary",
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {showcaseImages.map((image, index) => (
            <SwiperSlide key={index}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Image
                  src={image}
                  alt={`Book Page ${index + 1}`}
                  height={paginationSize === "sm" ? "100%" : "800px"}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default BookShowcase;
