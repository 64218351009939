// features/form/utils/Validator.js

const Validator = {
  isArabicText: (text) => {
    const arabicRegex = /^[\u0600-\u06FF\s]+$/;
    return arabicRegex.test(text);
  },
  isValidEmail: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  isPhotoUploaded: (file) => {
    return file != null;
  },
  isValidUniversity: (university) => {
    // Assuming you have a list of universities, you might check if the selected value is in the list
    return university !== "";
  },
  isValidCollege: (college) => {
    // Similar to university check
    return college !== "";
  },
  isArabicMajor: (major) => {
    return Validator.isArabicText(major);
  },
  isValidGraduationYear: (year) => {
    const yearInt = parseInt(year);
    return yearInt >= 2023 && yearInt <= 2026;
  },
  isValidStudentID: (id) => {
    const idRegex = /^4\d{8}$/;
    return idRegex.test(id);
  },
  isValidQuote: (quote) => {
    // Optional, under 150 characters
    return !quote || quote.length <= 150;
  },
  isValidPhoneNumber: (phoneNumber) => {
    const phoneRegex = /^(05\d{8}|5\d{8})$/;
    return phoneRegex.test(phoneNumber);
  },
};

export default Validator;
