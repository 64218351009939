import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

const FAQs = () => {
  const faqs = [
    {
      question: "من نحن؟",
      answer:
        "نعمل في خریّج بحب وشغف لتوثیق رحلة الخریجین والخریجات الجامعیة، نوثق اللحظات القیّمة والذكریات الجمیلة التي ستظل خالدة في الذاكرة، من خلال صفحات الكتاب یستذكر الخریّج رحلتھ الملیئة بالتحدیات والإنجازات، من لحظة الالتحاق في الجامعة مرو ًرا بالذكریات الجمیلة والصعوبات التي واجھھا وصولاً إلى یوم التخرج والنجاح الذي حققھ، تحتوي صفحات الكتاب على عبارات ملھمة ونصوص مؤثرة، مصحوبة بصورة تج ّسد لحظة استثنائیة لذكرى خالدة! خریج ..بدایة جدیدة لمسیرة حیاة، ومصدر إلھام لكل من یسعى لتحقیق أحلامھ .",
    },
    {
      question: "ماھي محتويات كتاب خریج؟",
      answer:
        "يحتوي الكتاب على صور الخريجين أوالخريجات مع عبارات اختاروها لتمثل مشاعرهم في يوم التخرج، ويوثق أبرز وأهم الإنجازات خلال السنة .. من إنجازات الجامعة، وإنجازات الطلاب او الطالبات وغيرها كما يوفر مساحات  لكل خريج ليوّثق إنجازاته الخاصة، نصائح وإرشادات وتجارب مثرية لمرحلة ما بعد التخرج .. كما يحتوي على الكثير من المحاور التي تستحق أن يخلد ذكراها للأبد! كتاب خريج أفضل ما تختم به مسيرتك الجامعية .",
    },
    {
      question: "من يستطيع اقتناء كتاب خريج؟",
      answer: "جميع الخريجين والخريجات من الجامعات المحددة لهذه السنة ٢٠٢٤",
    },
    {
      question: "كيف يمكنني حجز نسخة من كتاب خريج ؟",
      answer:
        "يمكنك تعبئة بياناتك وارفاق صورتك الشخصية، ثم الانتقال الى متجر خريج khreej.co وحجز نسختك",
    },
    {
      question: "ماهي العبارة التي يمكن ان أشارك بها؟",
      answer:
        "يمكنك أن تختار العبارة التي تمثل مشاعرك في آخر سنة لك كخريج، مثال : أقف أمام حلمي اليوم بكل فخر واهدي هذا النجاح لوالدي ووالدتي وكل من دعمني .",
    },
    {
      question: "هل استطيع اهداء الكتاب للخريج؟",
      answer:
        "نعم، كتاب خريج أجمل ما يقدم للخريجين والخريجات كهدية، ويمكنك إرسال الكتاب كاهداء إلى عنوان الخريج .",
    },
    {
      question: "لم أجد نسخة لجامعتي، هل يوجد نسخة لجامعات أخرى من كتاب خريج؟",
      answer:
        "نسعى باذن الله لتوفير كتاب خريج للجامعات الأخرى في السنة القادمة باذن الله .",
    },
    {
      question: "هل تعبئة بياناتي والمشاركة بعبارة خريج شرط لشراء الكتاب ؟",
      answer:
        "لا يشترط شراء الكتاب، ولكننا نسعى لتوثيق بصمة لكل خريج في الكتاب، لتخليد ذكريات المرحلة الجامعية وان يكون حلقة وصل بين زملاء الدراسة ومصدر للتحفيز على بناء مستقبل مشرق.",
    },
    {
      question: "لدي مشكلة في رفع صورتي الشخصية، ما امتلك صورة مناسبة؟",
      answer:
        "يمكنك التواصل مع فريق الدعم على الرقم : ٠٠٠٠ وفي حال اانه لا يتوفر لديك صورة مناسبة يمكنك حجز خدمة التصوير من خلال متجرنا الالكتروني لتنسيق الموعد المناسب لك.",
    },
    {
      question: "ماهي وسائل الدفع الالكتروني المتاحة؟",
      answer:
        "يمكنك الدفع بواسطة: البطاقات الائتمانية ( فيزا- ماستر كارد) Apple pay - مدى - تمارا - تابي",
    },
    {
      question: "ھل استطیع رفع صورة بملامح غیر واضحة؟",
      answer:
        "للطلاب یجب رفع صورة شخصیة حدیثة بالزي السعودي، یمكن للطالبات رفع صور بالحجاب،النقاب، صورة من الطفولة، صورة بملامح غیر واضحة",
    },
  ];

  return (
    <Box display="flex" justifyContent="center" width="100%" py={20}>
      <VStack spacing={4} align="space-between" p={8} width="100%" maxW={"4xl"}>
        <Text fontSize="2xl" fontWeight="bold" alignSelf="center" mb={6}>
          الاسئلة الشائعة 💬{" "}
        </Text>
        <Accordion allowToggle>
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              borderBottomWidth="1px"
              borderColor="gray.200"
              borderUpperWidth="0px"
            >
              <AccordionButton
                _expanded={{
                  bg: "greyBackground",
                  color: "secondary",
                  boxShadow: "md",
                }}
                py={4}
                _hover={{
                  bg: "gray.50",
                }}
              >
                <Box flex="1" textAlign="right">
                  <Flex justifyContent="space-between" width="100%">
                    <Box flex="1" textAlign="right" fontWeight={"Bold"}>
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </Flex>
                  <AccordionPanel pb={4} color="gray">
                    {faq.answer}
                  </AccordionPanel>
                </Box>
              </AccordionButton>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Box>
  );
};

export default FAQs;
