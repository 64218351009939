import React from "react";
import { Box, Text, Image, Flex, useBreakpointValue } from "@chakra-ui/react";

const PaymentWays = () => {
  const fontSize = useBreakpointValue({ base: "md", md: "xl" });
  const imageHeight = useBreakpointValue({ base: "20px", md: "50px" });

  return (
    <Box textAlign="center" my="4">
      <Text fontSize={fontSize} mb="4" fontWeight={"Bold"} my={10}>
        طرق الدفع 💰
      </Text>
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Image src="./Payment.svg" alt="Payment Methods" height={imageHeight} my={10} />
      </Flex>{" "}
    </Box>
  );
};

export default PaymentWays;