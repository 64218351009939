import React from "react";
import { Flex, useBreakpointValue, Text } from "@chakra-ui/react";
import ComponentCard from "./widgets/ComponentCard";

const BookComponents = () => {
  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <>
      <Text textAlign="center" fontWeight="bold" fontSize="3xl" m={4}>
        محتويات الكتاب
      </Text>
      <Flex
        direction={direction}
        justifyContent="center"
        alignItems="center"
        wrap="wrap"
        p={4}
      >
        <ComponentCard
          title="الخريجين و الخريجات"
          description="يوثق صور الخريجين مع عبارات تمثلهم في يوم التخرج."
          iconName="graduate"
        />
        <ComponentCard
          title="توثيق الانجازات والنجاحات"
          description="توثيق أبرز إنجازات الجامعة وطلاب وطالبات الدفعة خلال السنة"
          iconName="lecturer"
        />
        <ComponentCard
          title="تخليد للذكريات"
          description="يحفظ أبرز أحداث السنة في الجامعة ويخلد ذكراها"
          iconName="stats"
        />
        <ComponentCard
          title="الالهام والتحفيز"
          description="يحتوي على نصائح محفزة، محاور متنوعة لإرشادات المستقبل"
          iconName="motivation"
        />
        <ComponentCard
          title="حلقة وصل"
          description="يربط بين زملاء او زميلات الدراسة"
          iconName="connection"
        />
        <ComponentCard
          title="مساحة خاصة لك"
          description="يسمح لك بتوثيق إنجازاتك وذكرياتك بمساحتك الخاصة"
          iconName="personal"
        />
      </Flex>
    </>
  );
};

export default BookComponents;
