// GetYourBook.js
import React from "react";
import { Box, Button, Text, Flex, useTheme, VStack } from "@chakra-ui/react";

const GetYourBook = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={10}
      px={4}
      bg={theme.colors.greyBackground}
    >
      <Flex
        bg={theme.colors.primary}
        color="white"
        p={10}
        justifyContent="space-between"
        alignItems="center"
        flexDirection={["column", "row"]} // responsive direction
        position="relative"
        w="full"
        maxW={"4xl"}
        borderRadius="xl"
        boxShadow="xl"
      >
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontSize="2xl" fontWeight="bold">
            اصنع ذكریات تخرجك بكلماتك الخاصة ..🤩
          </Text>
          <Text fontSize="lg">و لا تفوت فرصة الحصول على كتابك الآن !</Text>
        </VStack>

        <Button
          size="lg"
          bg="white"
          color={theme.colors.primary}
          _hover={{
            bg: "whiteAlpha.800",
          }}
          boxShadow="lg"
          borderRadius="md"
          px={10}
          fontWeight="bold"
          alignSelf={{ base: "center", md: "auto" }}
          onClick={() => {
            window.location.href = "/form";
          }}
          my={[10, 0]}
        >
          انضم إلینا{" "}
        </Button>
      </Flex>
    </Box>
  );
};

export default GetYourBook;
