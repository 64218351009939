import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Icon,
  Tag,
  Center,
} from "@chakra-ui/react";
import {
  FaRegCalendarCheck,
  FaBook,
  FaShippingFast,
  FaLongArrowAltDown,
} from "react-icons/fa";
import { MdPrint } from "react-icons/md";

const ProjectTimeline = () => {
  const timelineItems = [
    {
      icon: FaRegCalendarCheck,
      color: "blue.500",
      text: "آخر موعد للتسجيل في الكتاب: ",
      date: "٣ يوليو",
    },
    {
      icon: FaBook,
      color: "green.500",
      text: "آخر موعد لطلب الكتاب: ",
      date: "٧ يوليو",
    },
    {
      icon: MdPrint,
      color: "orange.500",
      text: "طباعة الكتب: ",
      date: "٥ يوليو - ١٨ يوليو",
    },
    {
      icon: FaShippingFast,
      color: "red.500",
      text: "توصيل الكتب: ",
      date: "١٩ يوليو - ٥ اوقست",
    },
  ];

  return (
    <Box
      p={5}
      borderRadius="lg"
      boxShadow="md"
      maxWidth="800px"
      mx="auto"
      my={8}
      animation="fadeIn 0.8s"
      style={{ transition: "all 0.5s ease-in-out" }}
    >
      <VStack spacing={4} align="center">
        <Heading size="md" textAlign="center" my={4} color="primary">
          خطتنا 📌
        </Heading>

        {timelineItems.map((item, index) => (
          <Box key={index} w="full">
            <Center>
              <Tag>
                <Icon as={item.icon} w={6} h={6} m={3} color={item.color} />
              </Tag>
            </Center>
            <Center>
              <Text fontSize="md">{item.text}</Text>
            </Center>
            <Center>
              <Tag size="md" bg="secondary" color="white">
                {item.date}
              </Tag>
            </Center>
            {index < timelineItems.length - 1 && (
              <Center>
                <FaLongArrowAltDown size={"2rem"}  />
              </Center>
            )}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default ProjectTimeline;
