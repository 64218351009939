import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#1A2B3F",
    black: "#1E1E1E",
    greyBackground: "#FAFAFA",
    secondary: "#FFBF00",
  },
  fonts: {
    heading: `'IBM Plex Sans Arabic', sans-serif`,
    body: `'IBM Plex Sans Arabic', sans-serif`,
  },
  styles: {
    global: {
      body: {
        color: "primary",
        bg: "white",
      },
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
    direction: "rtl",
  },
});

export default theme;
