import React from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import BookFeatures from "./components/BookFeatures";
import BookShowcase from "./components/BookShowcase";
import PaymentWays from "./components/PaymentWays";
import GraduateComments from "./components/GraduatesComments";
import OurGoals from "./components/OurGoals";
import GetYourBook from "./components/GetYourBook";
import FAQs from "./components/FAQs";
import BookComponents from "./components/BookComponents";
import University from "./components/University";
import ProjectTimeline from "./components/projectTimeline";
const MotionBox = motion(Box);

const Home = () => {
  // Define the animation variants
  const variants = {
    hidden: { opacity: 0, y: 20, scale: 0.9 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  // Animation settings for the scroll
  const scrollAnimationSettings = {
    initial: "hidden",
    whileInView: "visible",
    viewport: { once: true },
    transition: { duration: 0.6, type: "spring" },
  };

  return (
    <Box>
      <MotionBox {...scrollAnimationSettings} variants={variants}>
        <BookFeatures />
      </MotionBox>

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.1 }}
      >
        <BookComponents />
      </MotionBox>

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.2 }}
      >
        <BookShowcase />
      </MotionBox>

      <ProjectTimeline />

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.3 }}
      >
        <PaymentWays />
      </MotionBox>

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.4 }}
      >
        <GraduateComments />
      </MotionBox>

      {/* <MotionBox {...scrollAnimationSettings} variants={variants} transition={{ ...scrollAnimationSettings.transition, delay: 0.5 }}>
        <OurGoals />
      </MotionBox> */}

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.6 }}
      >
        <GetYourBook />
      </MotionBox>

      {/* 
      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.7 }}
      >
        <University />
      </MotionBox> */}

      <MotionBox
        {...scrollAnimationSettings}
        variants={variants}
        transition={{ ...scrollAnimationSettings.transition, delay: 0.8 }}
      >
        <Box id="FAQs">
          <FAQs />
        </Box>
      </MotionBox>
    </Box>
  );
};

export default Home;
