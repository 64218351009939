import React from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const AboutUs = () => {
  const textColor = "primary";

  const sectionVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <VStack spacing={8} as="section" py="40px">
      <MotionBox
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariant}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <Heading as="h1" size="xl" textAlign="center">
          من نحن
        </Heading>
        <Image
          src="./khreej_logo.png"
          alt="Khreej Logo"
          boxSize={{ base: "20%", md: "20%" }}
          mx="auto"
        />
      </MotionBox>

      <MotionBox
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariant}
        transition={{ duration: 0.8, delay: 0.4 }}
        textAlign="center"
        px={{ base: 4, md: 8 }}
      >
        <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
          نعمل في خريّج بحب وشغف لتوثيق مسيرة الخريجين ونجاحاتهم في كتيّب خريج،
          بطباعة عالية الجودة تعكس جمال اللحظة وتفاصيلها، تحتوي صفحات الكتاب على
          عبارات تروي قصة ملهمه لكل خريّج، مصحوبة بصورة ملونة تجسّد لحظة
          استثنائية في العمر ونصوصٍ مؤثرة لذكرى خالدة! يروي لنا الكتيّب رحلة
          ممتعة من النحديّات والانجازات و ذكرياتٌ من الحنين! خريّج: الهدية
          المثالية ليوم التخرّج.
        </Text>
      </MotionBox>
    </VStack>
  );
};

export default AboutUs;
