import React from "react";
import {
  Box,
  Text,
  Image,
  Link,
  useBreakpointValue,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { FiArrowUpLeft } from "react-icons/fi";

const BookFeatures = () => {
  const fontSize = useBreakpointValue({ base: "5xl", md: "6xl" });
  const pictureSize = useBreakpointValue({ base: "400px", md: "500px" });
  return (
    <Box as="BookFeatures">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={10}
      >
        <Text fontSize={fontSize} p={4} fontWeight={"600"}>
          ذكرى تخرجك{" "}
          <Text as="span" color="secondary">
            المميزة
          </Text>{" "}
        </Text>
        <Text fontSize="sm" fontWeight={"400"}>
          اصنع بصمتك الخاصة، في صفحات كتاب تخرجك{" "}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Link px={3} href="/form" _hover={{ textDecoration: "none" }}>
          <Text
            as="span"
            fontWeight="bold"
            border="1px"
            borderColor="primary"
            borderRadius="md"
            p={2}
            px={5}
            fontSize={"md"}
            bg="primary"
            color="white"
          >
            سجل بياناتك الآن واترك بصمة{" "}
          </Text>
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={10}
        px={2}
      >
        <Image src="./frame1.webp" alt="Book" height={pictureSize} />
      </Box>
    </Box>
  );
};

export default BookFeatures;
