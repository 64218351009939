import create from 'zustand';

const useUserStore = create((set) => ({
  userInfo: {
    gender: 'boys',
    firstName: '',
    lastName: '',
    image: null,
    university: '',
    college: '',
    major: '',
    graduationYear: '',
    studentId: '',
    memory: '',
    phone: '',
    middleName: '',
  },
  setUserInfo: (userInfo) => set((state) => ({ userInfo: { ...state.userInfo, ...userInfo } })),
  resetUserInfo: () => set({ userInfo: {} }),
}));

export default useUserStore;
