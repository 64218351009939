import React from "react";
import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const RulesAndRegulations = () => {
  const textColor = "primary";

  const sectionVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <VStack spacing={8} as="section" py="40px">
      <MotionBox
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariant}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <Heading as="h1" size="xl" textAlign="center">
          قوانين ولوائح
        </Heading>
        <Image
          src="./khreej_logo.png"
          alt="Khreej Logo"
          boxSize={{ base: "20%", md: "20%" }}
          mx="auto"
        />
      </MotionBox>

      <MotionBox
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariant}
        transition={{ duration: 0.8, delay: 0.4 }}
        textAlign="center"
        px={{ base: 4, md: 8 }}
      >
        <Heading> سياسة الخصوصية لدى منصة خريج </Heading>
        <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
            سياسة الخصوصية لدى منصة خريج.
            تلتزم منصة خريج بحماية خصوصية العملاء، ونطبق في منصة خريج. سياسة خصوصية معتمدة تضمن لكم الحفاظ على المعلومات الشخصية التي يتم جمعها من قبلنا أو تقديمها لنا، على أي موقع من مواقعنا الإلكترونية الخاصة بالهاتف الجوال أو التطبيقات أو الحاسب الآلي وغيرها من الروابط الموجودة على صفحاتنا الرسمية التي نديرها على صفحات وسائل التواصل الاجتماعية الخاص بنا، ومن خلال رسائل HTML التي نرسلها إليكم، يعتبر قيامكم بالتسجيل عبر منصة خريج موافقة على جميع الشروط والأحكام  الخاصة بالمستخدم وسياسة الخصوصية وسياسة الاسترجاع والاستبدال و يعتبر موافقة من قبلكم بمشاركة بياناتكم ومعلوماتكم الشخصية مع منصة خريج، و أن قيامك بالتسجيل في منصة خريج. والضغط على الإقرار يعتبر موافقة منك بالالتزام "سياسة الخصوصية" "الشروط والأحكام لمقدم الخدمة والمستخدم" "سياسة الا

            إذا كنت غير موافق على هذه الشروط والأحكام يرجى عدم استخدام منصة خريج أو خدماتها أو تطبيقاتها.  لذا يرجى مراجعة هذه الصفحة بشكل دوري لأي تعديلات. أنت تقر بمسؤوليتك بأن تستعرض هذه الصفحة بصورة دورية للاطلاع على آخر المستجدات، وتتنازل عن أي حق في تلقي إخطار محدد بكل تغيير من هذا القبيل، ويعد استمرارك في استخدام منصة بعد تاريخ نشر الشروط والأحكام المنقحة إقراراً منك بأنك اطلعت على التعديلات وفهمت مضمونها وقبلت الالتزام به.

                </Text>
                <Heading>
                    أولا: كيف نجمع المعلومات الشخصية؟
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    تقوم منصة على جمع المعلومات الشخصية عن طريق الآتي:
                    1-الأجهزة الخاصة بكم أو المتصفح: يتم جمع بعض المعلومات من خلال المتصفحات أو من خلال جهازكم تلقائيا أو من خلال النقر على الموافقة على التتبع، ونستخدم هذه المعلومات لأغراض الإحصائيات وضمان حسن عمل المواقع.
                    2- سجل الخادم: نقوم بجمع المعلومات عن طريق "عنوان بروتوكول الإنترنت" عبارة عن رقم يقوم بتعريف كل كمبيوتر عبر الإنترنت أو الشبكة أو الجهاز الذي تستخدمونه من قبل موفّر خدمة الإنترنت الخاص بكم. ويتمّ تحديد عنوان بروتوكول الإنترنت وتسجيله تلقائيًا في ملفات سجل الخادم كلّما قام المستخدم بزيارة موقع منصة خريج، مع تحديد وقت الزيارة والصفحة/الصفحات التي جرت زيارتها.
                    3- ملفات تعريف الارتباط (الكوكيز): تتيح ملفات تعريف الارتباط لخادم الويب نقل البيانات إلى حاسوب أو جهاز لغرض حفظها ولأغراض أخرى. إن كنتم لا تريدون أن يتم جمع المعلومات من خلال استخدام ملفات تعريف الارتباط، هناك إجراء بسيط متوفر في معظم المتصفحات يتيح لكم عدم استخدام ملفات تعريف الارتباط.
                    4-التسجيل: يتم جمع معلومات الاسم/ تاريخ الولادة الجنس / الرمز البريدي، رقم الجوال أو الهاتف / البريد الإلكتروني بالإضافة إلى غيرها من المعلومات، مثل وسائل الاتصّال المفضلة لديكم، عندما تقومون أنتم بتوفير هذه المعلومات طوعًا.
                    5- الموقع الجغرافي: يتم معلومات حول الموقع الجغرافي لجهازكم.
                </Text>
                <Heading>
                    ثانيا: المعلومات الشخصية التي نقوم بجمعها واستخدامها في منصة خريج.
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    نقوم في منصة خريج. بجمع البيانات الشخصية لأغراض التواصل معكم، وجميع البيانات والمعلومات التي يتم جمعها تكون لأغراض خاصة بمنصة خريج. ويتم جمعها في حدود تحسين جودة الخدمات وتجربة وزوار منصة خريج ومستخدميها بالإضافة الى لدراسة وتحسين جودة خدمة العملاء، تحسين جودة التطبيق، الإحصائيات، تحسين العروض والخدمات التي يقدمها التطبيق وفقا للملاحظات والمعلومات التي نتلقاها منك عبر منصتنا أو وسائل التواصل المختلفة، إرسال الإشعارات والمعلومات للبريد الإلكتروني، إرسال بريد الإلكتروني بشكل دوري، يستخدم البريد الإلكتروني الذي يسجله العميل لأرسال المعلومات والبيانات المتعلقة بالطلبات وتلقي الإشعارات والذكيرات في حال أردتم الانضمام الى قائمة مراسلاتنا للوصول الى اخر العروض والتحديثات والأخبار المستقبلية سوف يتلقى الرسائل على البريد الإلكترون
                    ونستخدمها بالآتي:
                    1-	الرد على استفساراتكم وتلبية طلباتكم، مثل إرسال النشرات الإخبارية لكم أو الإجابة على أسئلتكم والرد على التعليقات في منصة خريج، ولإرسال معلومات لكم، مثل المعلومات المرتبطة بالموقع والتعديلات التي تطرأ على شروطنا وأحكامنا وسياساتنا. وبما أنّ هذه المعلومات قد تكون مهمّة لاستخدامكم، لا يمكنكم التوقف عن تلقّي هذه الرسائل.
                    2-	لاستكمال وإنجاز عمليات الدفع، ومعالجة مدفعاتكم، وتوصيل الطلبيات، التواصل معكم فيما يخصّ عملية الشراء والدفع الخاصة بكم وتوفير خدمة العملاء المناسبة لكم.
                    3-	نقوم بإرسال التحديثات والإعلانات المتعلقة بخدماتنا ومنتجاتنا.
                    4-	يتم استخدامها لأغراض الأعمال في منصة خريج، مثل تحليل وإدارة أعمالنا، وإجراء دراسات السوق، ومراجعة الحسابات، وتطوير منتجات جديدة، وتعزيز مواقعنا، وتحسين خدماتنا ومنتجاتنا، وتحديد اتجاهات الاستخدام، وتحديد فعالية حملاتنا الترويجية، وتصميم تجربة ومحتويات الموقع خصيصًا بالاعتماد على نشاطاتكم السابقة على الموقع، وقياس رضا العملاء، وتوفير خدمة العملاء (بما فيها استكشاف الأخطاء وإصلاحها فيما يتعلّق بقضايا العملاء).
                    نستخدم المعلومات التي يوفرها المتصفح أو جهازكم لأغراض الإحصائيات وضمان حسن عمل الموقع، قد نستخدم
                </Text>
                <Heading>
                    ثالثا: كيف نحمي معلوماتكم الشخصية؟
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    تلتزم منصة خريج. بالقيام بالمحافظة على أمن وسلامة معلومات مستخدميها، ونتعهد بالقيام بأقصى معايير الأمان لحفظ وجمع البيانات والتعامل معها والتي تشمل: - اسم المستخدم، البيانات الشخصية، كلمة المرور، المعلومات والبيانات المخزنة في التطبيق، ونضمن بأنه يتم تداول جميع المعلومات والبيانات الحساسة عبر قنوات اتصال مؤمنة يتم تشفيرها وحمايتها من خلال عدة طرق وأساليب رقمية معتمدة.
                    نخزن كافة المعلومات الشخصية المعرفة بالأشخاص ومعلومات المواقع الشخصية بشكل آمن ضمن قاعدة البيانات الخاصة بمنصة خريج،
                    لكن بالمقابل يجب على المستخدم معرفة بأنه لا يوجد نظام أمن معلومات عصيّ على الاختراق بالكامل مهما بلغت مواصفاته وإجراءاته، لذلك فإن أي ‏معلومات تقوم بإرسالها إلى الشركة تكون على مسؤوليتك ال
                </Text>
                <Heading>
                    رابعا: مشاركة المعلومات الشخصية
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    نحن في منصة خريج. نتعهد بعدم بيع أو تأجير أو تبادل أو نقل أو إفشاء معلوماتكم الشخصية لأي طرف ثالث، ولكن قد نقوم بمشاركة المعلومات الشخصية مع الجهات الرسمية والحكومية والقضائية والأمنية والمالية والتجارية والتأمينية والمحاسبية والمحامية والمحكمة والمحكمة العليا والمحكمة الدستورية والمحكمة الإدارية والمحكمة الجنائية والمحكمة العسكرية والمحكمة العمالية والمحكمة الشرعية والمحكمة الإدارية العليا والمحكمة الجنائية الدولية والمحكمة العسكرية الدولية والمحكمة العمالية الدولية والمحكمة الشرعية الدولية والمحكمة الإدارية العليا الدولية والمحكمة الجنائية الدولية والمحكمة العسكرية الدولية والمحكمة العمالية الدولية والمحكمة الشرعية الدولية والمحكمة الإدارية العليا الدولية والمحكمة الجنائية الدولية والمحكمة العسكرية الدولية والمحكمة العمالية الدولية والمحك
                </Text>
                <Heading>
                    خامسا: تعديلات على سياسة الخصوصية
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    يحق للمنصة تعديل الإحكام والسياسات دون أن يتم إخطارك بكل تعديل أو تغيير على هذه، وكما يحق للمنصة تعديل السياسات والشروط والأحكام في أي وقت، ويعد استخدامك للمنصة بعد تعديل سياسة الخصوصية الشروط وسياسة المستخدم بمثابة موافقة ضمنية على التعديل لذا يرجى مراجعة الشروط والأحكام بشكل دوري لأي تعديلات.
                </Text>
                <Heading>
                    سادسا: الرفض
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    إذا كنت غير موافق على هذه الشروط والأحكام يرجى عدم استخدام منصة خريج أو خدماتها أو تطبيقاتها.  لذا يرجى مراجعة هذه الصفحة بشكل دوري لأي تعديلات. أنت تقر بمسؤوليتك بأن تستعرض هذه الصفحة بصورة دورية للاطلاع على آخر المستجدات، وتتنازل عن أي حق في تلقي إخطار محدد بكل تغيير من هذا القبيل، ويعد استمرارك في استخدام منصة بعد تاريخ نشر الشروط والأحكام المنقحة إقراراً منك بأنك اطلعت على التعديلات وفهمت مضمونها وقبلت الالتزام به.
                </Text>
                <Heading>
                    سابعا: القانون الواجب التطبيق
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    يخضع هذا الاتفاق للقوانين والأنظمة السارية في المملكة العربية السعودية.
                </Text>
                <Heading>
                    ثامنا: الاتصال
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    للتواصل معنا يمكنكم التواصل معنا عبر البريد الإلكتروني:
                    info@khreej.co
                </Text>
                <Heading>
                    تاسعا: الإقرار
                </Heading>
                <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                    إذا كنت غير موافق على هذه الشروط والأحكام يرجى عدم استخدام منصة خريج أو خدماتها أو تطبيقاتها.  لذا يرجى مراجعة هذه الصفحة بشكل دوري لأي تعديلات. أنت تقر بمسؤوليتك بأن تستعرض هذه الصفحة بصورة دورية للاطلاع على آخر المستجدات، وتتنازل عن أي حق في تلقي إخطار محدد بكل تغيير من هذا القبيل، ويعد استمرارك في استخدام منصة بعد تاريخ نشر الشروط والأحكام المنقحة إقراراً منك بأنك اطلعت على التعديلات وفهمت مضمونها وقبلت الالتزام به.
                </Text>


        </MotionBox>
    </VStack>
    );
}
export default RulesAndRegulations;


