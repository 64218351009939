import React, { useEffect } from "react";
import { VStack, Text, Box } from "@chakra-ui/react";
import Step1 from "./steps/Step1";
import FAQs from "../home/components/FAQs";
import CustomStepper from "./components/CustomStepper";
import useStepStore from "./stores/stepStore";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import LastStep from "./steps/LastStep";
import "@lottiefiles/lottie-player";

const Form = () => {
  const { currentStep } = useStepStore();

  const textToShow = {
    0: {
      title: "لطلب الذكرى المثالیة لیوم تخرجك ! 🎓",
      description: "نحتاج المعلومات الشخصیة .. لنكمل إنشاء حسابك في خریّج",
    },
    1: {
      title: " اطلب كتاب التخرج الخاص بك 👑:",
      description:
        " قم بتعبئة النموذج أدناه لطلب كتاب تخرج مخصص لك، وسنقوم بالتواصل معك قريبًا لبدء تنفيذ طلبك",
    },
    2: {
      title: "أكد لنا معلوماتك 🫡",
      description: "تأكد من معلوماتك قبل الانتقال للخطوة الاخيرة",
    },
    3: {
      title: "مبروك یا خریّج .. استلمنا معلوماتك .. و بنضیفھا في كتاب خریج✅",
      description: "احجز نسختك من كتاب تخرجك",
    },
    4: {
      title: "استلمنا معلوماتك🔥",
      description: "لا تنسى تشارك الناس فرحتك على مواقع التواصل الاجتماعي",
    },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentStep]);

  const isFormClosed = true; 

  return (
    <VStack p={4} height="50rem" flex="1" justifyContent="center">
      {isFormClosed && (
        <Box
          borderWidth={2}
          borderColor="secondary"
          borderRadius="md"
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          animation="fadeIn 2s"
        >
          <lottie-player
            src="./bookProd.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", height: "200px" }}
            loop
            autoplay
          ></lottie-player>
          <Text fontSize="2xl" fontWeight="bold" color="red.500" textAlign="center" m={3}>
            🚫 عذرًا، وقفنا استقبال الطلبات الجديدة!
          </Text>
          <Text fontSize="lg" fontWeight="600" color="gray.700" textAlign="center" m={2}>
            حالياً قاعدين نجهز ونطبع الكتاب علشان نشحنه لكم! 🚀🚚
          </Text>
        </Box>
      )}
      {!isFormClosed && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="xl" p={4} fontWeight={"600"} textAlign={"center"}>
              {textToShow[currentStep].title}
            </Text>
            <Text fontSize="sm" fontWeight={"400"} textAlign={"center"}>
              {textToShow[currentStep].description}
            </Text>
          </Box>
          <CustomStepper
            currentStep={currentStep}
            steps={useStepStore.getState().steps}
          />
          {currentStep === 0 && <Step1 />}
          {currentStep === 1 && <Step2 />}
          {currentStep === 2 && <Step3 />}
          {currentStep === 3 && <Step4 />}
          {currentStep === 4 && <LastStep />}
          <FAQs />
        </>
      )}
    </VStack>
  );
};

export default Form;
